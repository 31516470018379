import * as React from 'react'
import Layout from '../components/layout'
import { Link, StaticQuery, graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

const InsulationAndSealing = () => {

  return (
    <Layout>

      <section id="hero" className='py-10 sm:py-20 px-8 bg-slate-100'>
        <div className='max-w-4xl mx-auto'>
          <div id="insulation-icon-container" className="flex items-center justify-center w-24 h-24 rounded-full ring-1 ring-slate-900/10 shadow">
            <div className='flex items-center justify-center h-[95%] w-[95%] bg-emerald-200 p-4 rounded-full'>
              <svg width="75%" height="75%" viewBox="0 0 202 160" version="1.1">
                  <g id="Home-page" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="insulation-icon" transform="translate(-149.000000, -170.000000)" fill="#38A169" stroke="#38A169">
                          <g id="insulation-roll-icon" transform="translate(150.000000, 171.000000)">
                              <path d="M125.688073,78.8990826 C125.688073,52.6106032 104.291325,31.1926606 77.9679871,31.1926606 C51.6719781,31.1926606 30.2752294,52.6125902 30.2752294,78.8990826 C30.2752294,105.187562 51.6719781,126.605505 77.9679871,126.605505 C104.289337,126.605505 125.688073,105.187562 125.688073,78.8990826 Z M34.6691602,78.8990826 C34.6691602,55.0322112 54.1196207,35.5604962 77.9928312,35.5604962 C101.866539,35.5604962 121.316502,55.0322112 121.316502,78.8990826 C121.316502,102.765954 101.866042,122.210348 77.9655027,122.210348 C54.1191238,122.210348 34.6686633,102.765457 34.6686633,78.8990826 L34.6691602,78.8990826 Z" id="Shape" fill-rule="nonzero"></path>
                              <path d="M114.678899,78.8990826 C114.678899,58.6405079 98.4391591,42.2018349 78.4257442,42.2018349 C58.4123292,42.2018349 42.2018349,58.6405079 42.2018349,78.8990826 C42.2018349,99.1300604 58.4415748,115.59633 78.4549898,115.59633 C98.4684047,115.59633 114.678899,99.1305622 114.678899,78.8990826 Z M46.5614134,78.8990826 C46.5614134,61.0845826 60.8570639,46.6414106 78.4287183,46.6414106 C95.99839,46.6414106 110.323286,61.0845826 110.323286,78.8990826 C110.323286,96.6859857 96.0276356,111.127151 78.4559812,111.127151 C60.8843267,111.127151 46.5614134,96.6859857 46.5614134,78.8990826 Z" id="Shape" fill-rule="nonzero"></path>
                              <path d="M44.9842654,35.1511985 C45.9712617,34.4232449 46.167916,33.0531733 45.4069891,32.0734087 C44.6460925,31.093694 43.2658379,30.8984906 42.2788416,31.6538036 C27.0860752,43.0962151 18.3486239,60.5286306 18.3486239,79.4694139 C18.3486239,80.7008896 19.3631527,81.6513761 20.576299,81.6513761 C21.7874318,81.6513761 22.8020111,80.7009395 22.8020111,79.4694139 C22.8020111,61.9260817 30.8906592,45.7816994 44.9841346,35.1511785 L44.9842654,35.1511985 Z" id="Path"></path>
                              <path d="M58.1881384,26.6055046 C58.4695894,26.6055046 58.7234893,26.5473159 58.9773842,26.4329417 C65.2126479,24.0732371 71.7885208,22.8954395 78.5039627,22.8954395 C79.7163499,22.8954395 80.733945,21.860083 80.733945,20.6220317 C80.733945,19.3559339 79.7183654,18.3486239 78.5039627,18.3486239 C71.2529179,18.3486239 64.1696584,19.6428195 57.4265041,22.2031642 C56.2691888,22.6345662 55.6767506,23.9287926 56.0999423,25.1085936 C56.4384756,26.0296068 57.2848188,26.605484 58.1881888,26.605484 L58.1881384,26.6055046 Z" id="Path"></path>
                              <path d="M199.16749,153.848526 L164.00686,126.048111 C163.617871,125.73954 163.143292,125.571582 162.641502,125.571582 L141.869361,125.571582 C151.460027,112.469065 157.149101,96.3412744 157.149101,78.8990826 C157.149101,35.3633477 121.877438,0 78.5745505,0 C35.2716631,0 0,35.3623478 0,78.8990826 C0,122.407319 35.2442783,157.798165 78.5745505,157.798165 L197.803128,157.798165 C198.750295,157.798165 199.559392,157.210309 199.893935,156.313876 C200.172061,155.417444 199.893935,154.439017 199.168486,153.849211 L199.16749,153.848526 Z M4.38167264,78.8991476 C4.38167264,37.7987301 37.6731635,4.42473315 78.5796291,4.42473315 C119.483605,4.42473315 152.777586,37.7992301 152.777586,78.8991476 C152.777586,119.972067 119.512982,153.373562 78.5796291,153.373562 C37.675653,153.373562 4.38167264,119.971567 4.38167264,78.8991476 Z M138.388101,129.965318 L151.771318,129.965318 L173.045348,153.371562 L104.423939,153.371562 C117.752884,148.696113 129.406872,140.548524 138.386608,129.965318 L138.388101,129.965318 Z M178.984869,153.371562 L157.738223,129.965318 L161.892652,129.965318 L191.447856,153.371562 L178.984869,153.371562 Z" id="Shape" fill-rule="nonzero"></path>
                          </g>
                      </g>
                  </g>
              </svg>
            </div>
          </div>
          <h1 className='text-4xl lg:text-5xl'>Insulation and Sealing</h1>
          <p className='mt-4 font-normal text-xl'>
            Drafty, leaky homes are uncomfortable. But they're also incredibily wasteful. In fact, most 
            homeowners can cut their utility bills by $500 to $1,000 per year by sealing leaky windows and doors 
            and improving their insulation. 
          </p>
          <p className='mt-4 font-normal text-xl'>
            Read the guides below to learn more about how insulation and air sealing can make your home more 
            comfortable and save you a lot of money.
          </p>
        </div>
      </section>

      <section id="featured-article" className='py-10 sm:py-20 px-8'> 
        <div className='max-w-4xl mx-auto'>
          <div className='max-w-2xl'>
            <h2 className='text-emerald-400 text-2xl font-semibold'>Featured article</h2>
            <Link to="/home-insulation-savings/" className='no-underline'>
              <h3 className='text-3xl lg:text-4xl max-w-5xl mt-4 mb-6'>How to Save Money and Energy Insulating Your Home</h3>
              <StaticImage 
                src="../images/home-images/home-insulation.png" 
                alt="Insulation savings image" 
              />
            </Link>
          </div>
        </div>
      </section>

      <section>
        
      <StaticQuery 
        query={graphql`
          query InsulationAndSealingGuides {
            allWpPost(
              sort: {order: DESC, fields: [date]}
              filter: {categories: {nodes: {elemMatch: {slug: {eq: "insulation-and-sealing"}}}}}
            ) {
              edges {
                page: node {
                  id
                  uri
                  title
                  content
                  featuredImage {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div className="max-w-4xl mx-auto mt-10 mb-24">
            <div className="max-w-3xl px-8 sm:px-0">
                <h2>Insulation and air sealing articles</h2>
                <p>We've spent hundreds of hours doing research on things like attic insulation, 
                  basement insulation, crawlspace insulation, and air sealing. Here's what we've learned.
                </p>
                {data.allWpPost.edges.map(({ page }, index) => (
                  <div id="parent-JSX-div" > 
                    <div 
                      id="top-dividing-line" 
                      className='border-t border-slate-200 my-8'
                    ></div>
                    <div 
                      id="content-cards"
                      key={index}
                      className="flex flex-col-reverse sm:items-center sm:flex-row"
                    >
                      <Link to={page.uri} className='sm:max-w-xs'>
                        <GatsbyImage 
                          image={getImage(page.featuredImage.node.localFile.childImageSharp.gatsbyImageData)}
                        />
                      </Link>
                      <Link 
                        to={page.uri}
                        className="text-3xl font-extrabold text-slate-800 no-underline mb-4 sm:pl-4" 
                      >
                        {page.title}
                      </Link>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}

        />
        
      </section>

    </Layout>
  )
}

export default InsulationAndSealing